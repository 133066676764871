import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import PrimaryFeatures from "../components/PrimaryFeatures";
import SecondaryFeatures from "../components/SecondaryFeatures";
import TertiaryFeatures from "../components/TertiaryFeatures";
import OthersFeatures from "../components/OthersFeatures";
import ApiPart from "../components/ApiPart";
import Faqs from "../components/Faqs";
import Footer from "../components/Footer";
import NewJourney from "../components/NewJourney";

function Home() {
  return (
    <>
      <Header />
      <main>
        <HeroSection />
        <div className="h-full w-full bg-white">
          <PrimaryFeatures />
          <SecondaryFeatures />
          <TertiaryFeatures />
          <OthersFeatures />
          <ApiPart />
          {/*<Faqs />*/}
          <NewJourney />
          <Footer />
        </div>
      </main>
    </>
  );
}

export default Home;
