import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

function ApiPart() {
  const actions = [
    "Dashboard pour tracker ses transactions",
    "Exporter ses transactions en un clic",
    "Faire des paiements de masse",
    "Collecter des fonds",
  ];

  return (
    <section
      id="pricing"
      className="relative w-screen  text-white overflow-hidden font-poppins"
    >
      <div className="relative py-20  flex flex-col  h-full w-full overflow-hidden ">
        <div className="inset-0  absolute bg-[#000C24] rounded-[50%] h-[350vh] w-[250vw] lg:w-[160vw] z-0 -left-[75.5vw] lg:-left-[30vw] " />

        <div className="relative text-white flex flex-col items-center text-center space-y-5 z-10 ">
          <div className="z-10 flex flex-col items-center text-center space-y-5 mb-28 ">
            <p className=" text-3xl lg:text-6xl  max-w-sm lg:max-w-xl   leading-[120%] font-semibold text-gray-200">
              SCKALER API DEVELOPPEUR
            </p>
            <p className="text-xl lg:text-2xl max-w-xl">
              Les APIs de SCKALER vous permettent d'intégrer nos services
              en personnalisant vos parcours
              clients et acceptant différents moyens de paiement
            </p>
            <div className="my-5 flex flex-col lg:flex-row lg:space-x-5 space-y-5 lg:space-y-0 items-center ">
              <div className="bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500 rounded-3xl p-[1px]">
                <Link to="https://dashboard.sckaler.cloud/login">
                  <button className="min-w-48 bg-[#000C24] hover:bg-indigo-950 py-2 px-5 rounded-3xl text-white text-lg font-bold">
                    Se connecter
                  </button>
                </Link>
              </div>

              <Link
                to="https://dashboard.sckaler.cloud/register"
                className="min-w-48 bg-[#253062] hover:bg-indigo-950 py-2 px-5 rounded-3xl text-white text-lg font-bold border-[0.5px] border-white"
              >
                Créer un compte
              </Link>
            </div>
          </div>

          <img
            className="inset-0 object-cover absolute z-0 lg:h-[70vh] w-auto lg:left-[15vw]  lg:-top-36 "
            alt="gradiant"
            src="/group-3@2x.png"
          />
        </div>
        <div className="relative mb-32">
          <div className="flex flex-col lg:flex-row w-full  text-lg items-center text-start px-10 lg:px-52 space-y-5 lg:space-x-0">
            <div className="flex flex-col space-y-14 w-full my-auto items-start text-base ">
              <span>
                Un contrat unique pour un accès à plusieurs modes de paiement
              </span>
              <span>
                Un service après vente disponible
              </span>
              <span>
                Un reversement automatique vers vos comptes bancaires
              </span>
            </div>

            <div className="w-full font-roboto">
              <div className=" px-5 py-2 shadow-lg text-gray-100 text-sm font-mono subpixel-antialiased bg-[#3f4146]  rounded-t-lg leading-normal overflow-hidden">
                <div className="top flex">
                  <div className="h-3 w-3 bg-red-500 rounded-full"></div>
                  <div className="ml-2 h-3 w-3 bg-orange-300 rounded-full"></div>
                  <div className="ml-2 h-3 w-3 bg-green-500 rounded-full"></div>
                </div>
              </div>
              <div
                className="flex items-center rounded-b-lg  shadow-lg text-gray-100 text-sm font-mono subpixel-antialiased
              bg-[#383A3F]  mb-6 leading-normal overflow-hidden"
              >
                <div className="h-full bg-black/20  flex flex-col items-center py-2 px-3">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                    (item) => {
                      return (
                        <span key={item} className="text-white">
                          {item}
                        </span>
                      );
                    },
                  )}
                </div>
                <div className="ml-2 mt-2 flex flex-col self-start">
                  <div className="flex">
                    <span className="text-indigo-400">sckaler:~$</span>
                    <p className="flex-1 items-center pl-2">
                      ./payment-sckaler(
                    </p>
                  </div>
                  <p className="flex-1  items-center pl-2">
                    &#123;
                    <br />
                    &nbsp;&nbsp;&nbsp;'mtn',
                    <br />
                    &nbsp;&nbsp;&nbsp;'moov',
                    <br />
                    &nbsp;&nbsp;&nbsp;'celtis'
                    <br />
                    &#125;)
                  </p>
                  <p className="flex-1  items-center pl-2"></p>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-14 w-full my-auto items-start lg:pl-10 text-base">
              <span>
                Une infrastructure robuste hautement disponible et redondante,
                hébergée en cloud
              </span>
              <span>
                Une Sandbox pour tester les APIs et préparer l'intégration
              </span>
              <span>
                Une réconciliation quotidienne de toutes les transactions de
                paiement
              </span>
            </div>
          </div>
        </div>
        <div className="relative px-5 lg:px-48 w-full bg-[#000C24] font-sora ">
          <div className="h-[100vh] lg:h-[40vh] w-full bg-white rounded-2xl flex flex-col lg:flex-row overflow-hidden mb-20">
            <div className="h-full lg:w-3/4 text-black flex flex-col p-8 justify-between">
              <div className="flex flex-col space-y-5">
                <h1 className="text-2xl font-semibold">
                  L'inscription est gratuite
                </h1>
                <p className="text-lg">
                  Des frais de 1,7% à la charge des utilisateurs sur chaque
                  transaction.
                </p>
              </div>
              <div className="flex flex-col space-y-5">
                <div className="flex items-center">
                  <h1 className="text-xl font-bold w-full lg:w-1/3">
                    Ce qui es inclus
                  </h1>
                  <div className="w-full h-0.5 bg-gray-100"></div>
                </div>
                <div className="grid lg:grid-cols-2 gap-4 ">
                  {actions.map((text, index) => {
                    return (
                      <div key={index} className="flex items-center space-x-5 ">
                        <IoCheckmarkCircleOutline className="h-5 w-5" />
                        <span className="text-sm">{text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="h-full  lg:w-1/4 [background:linear-gradient(180deg,_#0e1e51,_#919099)] flex flex-col items-center justify-center space-y-10 font-semibold">
              <div className="flex flex-col items-center">
                <span className=" text-7xl">1.7%</span>
                <span className="">par transaction</span>
              </div>

              <div className="flex flex-col items-center space-y-2">
                <Link
                  to="https://dashboard.sckaler.cloud/register"
                  className="bg-white hover:bg-gray-100 py-2 px-8 rounded-lg text-black text-base font-semibold"
                >
                  Commencez ici
                </Link>
                <span>Inscris toi, c'est gratuit</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ApiPart;
