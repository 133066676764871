import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { SquareMenu } from "lucide-react";
import { clsx } from "clsx";

const links = [
  { href: "/", name: "Acceuil", type: "text", newWindow: false },
  { href: "https://app.swaggerhub.com/apis/Maestro2496/payment-api/1.0.0", name: "Documentation", type: "text", newWindow: true },
  { href: "#pricing", name: "Tarification", type: "text", newWindow: false },
  { href: "/", name: "Contact", type: "text", newWindow: false },
  {
    href: "https://dashboard.sckaler.cloud/register",
    name: "Créer un compte",
    type: "button",
  },
];

function MobileNavLink({
  href,
  children,
  type,
    newWindow
}: {
  href: string;
  children: React.ReactNode;
  type: string;
  newWindow: boolean;
}) {
  return (
    <Popover.Button
      as={Link}
      to={href}
      target={newWindow ? "_blank" : "_self"}
      rel="noreferrer"
      className={clsx(
        type === "button" &&
          "bg-[#253062] rounded-3xl text-white font-semibold w-fit px-3",
        "block   py-2",
      )}
    >
      {children}
    </Popover.Button>
  );
}
function MobileNav() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none outline-0"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <SquareMenu className="w-8 h-8" />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className=" absolute inset-x-0 left-[20%] right-10  mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            {links.map((link, index) => {
              return (
                <MobileNavLink key={index} href={link.href} type={link.type} newWindow={link.newWindow ?? false}>
                  {link.name}
                </MobileNavLink>
              );
            })}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

function Header() {
  return (
    <header className="shadow relative z-20 bg-white flex flex-row justify-between text-black px-5 md:px-10 py-5 w-full font-poppins">
      <Link to="/">
        <img className="w-28  relative" loading="lazy" alt="" src="/logo.svg" />
      </Link>
      <div className="flex-row items-center justify-between space-x-5 hidden md:flex">
        {links.map((link, index) => {
          return link.type === "text" ? (
            <div key={index} className=" ">
              <Link to={link.href} 
                    target={link.newWindow ? "_blank" : "_self"}
                    rel="noreferrer"
              >{link.name}</Link>
            </div>
          ) : (
            <div
              key={index}
              className="bg-[#253062] py-2 px-5 rounded-3xl text-white text-lg font-semibold"
            >
              <Link to={link.href} 
                    target={link.newWindow ? "_blank" : "_self"}
                    rel="noreferrer"
              >{link.name}</Link>
            </div>
          );
        })}
      </div>

      <div className="flex md:hidden">
        <MobileNav />
      </div>
    </header>
  );
}

export default Header;
