function OthersFeatures() {
  return (
    <section className="bg-white w-screen py-5 px-5 md:px-32 text-black mb-32 font-poppins">
      <div className="flex flex-col items-center text-center mb-20 space-y-5">
        <h1 className="text-4xl font-semibold">
          Tous les moyens de paiement locaux.
        </h1>
        <h1 className="text-xl max-w-2xl">
          Plus besoin de jongler entre plusieurs opérateurs et prestataires pour avoir une
          solution de paiement complète. SCKALER se charge de tout.
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 h-full w-full my-10 justify-items-center">
        <img className="h-full w-auto" alt="mtnsvg" src="/mtn.svg" />
        <img className="h-full w-auto" alt="moovsvg" src="/moov.svg" />
        <img className="h-full w-auto" alt="celtissvg" src="/celtis.svg" />
        <img className="h-full w-auto" alt="ubasvg" src="/uba.svg" />
      </div>
    </section>
  );
}

export default OthersFeatures;
