import { Link } from "react-router-dom";

function NewJourney() {
  return (
    <section className="bg-white w-screen py-10 px-5 md:px-32  text-black font-poppins">
      <div className="flex flex-col items-center text-center  space-y-5">
        <h1 className="text-4xl font-semibold max-w-4xl">
          Créez l’expérience dont vous rêvez pour vos paiement
        </h1>
        <div className="my-5 flex flex-col md:flex-row md:space-x-5 space-y-5 md:space-y-0 items-center ">
          <div className="bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500 rounded-3xl p-[1px] shadow">
            <Link to="https://dashboard.sckaler.cloud/login">
              <button className="min-w-48 bg-white hover:bg-gray-100 py-2 px-5 rounded-3xl text-black text-lg font-bold">
                Se connecter
              </button>
            </Link>
          </div>

          <Link
            to="https://dashboard.sckaler.cloud/register"
            className="min-w-48 bg-[#253062] hover:bg-indigo-950 py-2 px-5 rounded-3xl text-white text-lg font-bold"
          >
            Créer un compte
          </Link>
        </div>
      </div>
    </section>
  );
}

export default NewJourney;
