import {Link} from "react-router-dom";

function NotFound() {
    return (
        <section className="bg-indigo-950 h-screen text-white flex flex-col justify-center">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center flex flex-col items-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl  ">404</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold  md:text-4xl text-white">Quelque chose s'est mal passé</p>
                    <img className="h-64 w-auto  relative" loading="lazy" alt="" src="/not-found-illustration.png"/>
                    <p className="mb-4 text-lg font-light text-gray-300">Désolé , la page que vous cherchez est introuvable. </p>
                    <Link to="/"
                       className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
                        Retournez à l'Accueil
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default NotFound;