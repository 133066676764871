import { BsBank2 } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa6";

function SecondaryFeatures() {
  return (
    <section className="bg-white w-screen py-5 px-5 lg:px-32 z-10 mb-32 font-poppins">
      <div className="flex flex-col lg:flex-row h-full w-full ">
        <div className="relative z-0 w-full lg:w-[60%] h-[70vh]">
          <img
            className="inset-0 mx-auto my-auto  h-auto w-full absolute object-cover"
            loading="lazy"
            alt="bank-icon"
            src="/group-45.svg"
          />
        </div>
        <div className="h-full w-full lg:w-[40%] flex flex-col space-y-8 items-center lg:items-start justify-center lg:px-0 py-10 z-10">
          <h1 className="font-semibold text-3xl lg:text-4xl">
            Transfert bancaire (Coming soon)
          </h1>
          <div className="flex flex-col space-y-5 max-w-sm text-lg ">
            <span className="">Un reporting consolidé de vos transactions</span>
            <span className="">Un suivi en temps réel de toutes les transactions</span>
            <span className="">
              Une veille et une gestion de fraude pour sécuriser vos transactions
            </span>
            <span className="">
              Une réconciliation quotidienne de toutes vos transactions
            </span>
            <span className="">
              Un paiement vers plusieurs bénéficiaires wallets et autres comptes en
              un clic
            </span>
            <span className="">
              Un embasement de la liste de bénéficiaires pour reconduire les paiements
            </span>
            <span className="">
              Un contact support unique, disponible de 7h à 23h, 7J/7
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecondaryFeatures;
