function TertiaryFeatures() {
  return (
    <section className="bg-white w-screen py-5 px-5 md:px-32  text-black mb-32 font-poppins">
      <div className="flex flex-col text-center mb-20 space-y-5">
        <h1 className="text-2xl">
          Gardez un contrôle sur toutes vos transactions en temps réel.
        </h1>
        <h1 className="text-4xl font-semibold">
          Tableau de bord simple et intuitif.
        </h1>
      </div>

      <div className="h-full w-auto my-10 [filter:drop-shadow(0px_-16px_51.1px_rgba(47,_74,_194,_0.3))]">
        <img className="h-auto md:h-full w-auto" alt="dashboard" src="/dashboard2.png" />
      </div>
    </section>
  );
}

export default TertiaryFeatures;
