import { IoLogoAppleAppstore, IoLogoGooglePlaystore } from "react-icons/io5";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <section className=" relative bg-white  overflow-hidden text-black font-poppins">
      <div className="w-full h-full flex flex-row items-start ">
        <div className="z-10 flex flex-col  self-center md:w-[50%] p-5 md:p-16">
          <h1 className="font-semibold text-5xl my-5">
            Simplifiez vos paiements, élevez votre expérience.
          </h1>

          <h1 className="font-medium text-2xl my-5">
            La Solution de Paiement en Ligne en Afrique et au Bénin qui
            Simplifie Vos Transactions et Stimule Vos Ventes
          </h1>

          <div className="my-5 flex flex-col md:flex-row md:space-x-5 space-y-5 md:space-y-0 items-center ">
            <div className="bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500 rounded-3xl p-[1px]">
              <Link to="https://dashboard.sckaler.cloud/login">
                <button className="min-w-48 bg-white hover:bg-gray-100  py-2 px-5 rounded-3xl text-black text-lg font-semibold">
                  Se connecter
                </button>
              </Link>
            </div>

            <Link
              to="https://dashboard.sckaler.cloud/register"
              className="min-w-48 bg-[#253062] hover:bg-indigo-950 py-2 px-5 rounded-3xl text-white text-lg font-semibold"
            >
              Créer un compte
            </Link>
          </div>
        </div>
        <img
          className="object-cover absolute md:relative -translate-y-20  md:h-[100vh] md:w-[50%]"
          alt=""
          src="/mask-group.svg"
        />
      </div>
    </section>
  );
}

export default HeroSection;
