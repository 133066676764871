import { BsCreditCard2Front, BsPhone } from "react-icons/bs";
import { FiCreditCard, FiLink } from "react-icons/fi";
import { MdNumbers } from "react-icons/md";
import { FcMultipleInputs } from "react-icons/fc";
import { GrMultiple } from "react-icons/gr";

function Networks() {
  return (
    <div className="flex -space-x-2 overflow-hidden">
      <img
        src="/mtnmomo.png"
        alt={"mtn-momo"}
        className="inline-block h-10 w-10 rounded-full ring-0 ring-white"
      />
      <img
        src="/moovmoney.png"
        alt={"moov-momo"}
        className="inline-block h-10 w-10 rounded-full ring-0 ring-white"
      />
      <img
        src="/celtiiscash.png"
        alt={"celtis-momo"}
        className="inline-block w-10 rounded-full ring-0 ring-white"
      />
    </div>
  );
}

function PrimaryFeatures() {
  const newFeatures = [
    {
      icon: BsPhone,
      title: "Collecte de paiement Mobile Money",
      subtitle:
        "Collectez en toute sécurité vos paiements et accédez à vos rapports d’activité.",
    },
    {
      icon: GrMultiple,
      title: "Paiement de masse",
      subtitle:
        "Payez des milliers de personnes en 1 clic sur leurs Mobile Money ou comptes bancaires.",
    },
    {
      icon: FiLink,
      title: "Lien du paiement",
      subtitle:
        "Générez facilement des liens de paiement sécurisés pour permettre à vos clients de vous payer.",
    },
    {
      icon: Networks,
      title: "Mobile Money",
      subtitle:
        "Bénéficiez de tous les paiements mobiles existants quelque soit le réseau GSM. Gardez le contrôle en temps réel des transactions effectuées dans votre entreprise",
    },
    {
      icon: MdNumbers,
      title: "USSD (*654#)",
      subtitle:
        "Intégrez le paiement de vos solutions plus facilement par USSD.",
    },
    {
      icon: BsCreditCard2Front,
      title: "Cartes bancaires (Coming soon ...)",
      subtitle:
        "Acceptez en toute sécurité, les paiements par cartes bancaire. Le service n'est pas encore disponible, mais vous pourrez bientôt gérer le paiement de vos solutions via tous types de carte bancaire.",
    },
  ];

  return (
    <section className=" bg-white overflow-hidden relative pb-20 lg:pb-56 font-poppins">
      <div className="mt-20 flex flex-col gap-10 items-center ">
        <h1 className="font-semibold text-4xl text-center">
          Vos encaissement en mieux
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 place-content-center z-10">
          {newFeatures.map((feature, index) => (
            <div
              key={index}
              className="w-[90vw] lg:w-[30vw] h-[40vh] lg:h-72 backdrop-blur-sm bg-white/55 rounded-xl border-[0.5px] border-white shadow"
            >
              <div className="h-full w-full flex flex-col px-8 pt-5 justify-start">
                <feature.icon className="h-8 w-8 text-black" />
                <h1 className="text-2xl font-semibold mt-2 my-2">
                  {feature.title}
                </h1>
                <p className="text-base leading-[24px]">{feature.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
        <img
          className="inset-0 object-cover absolute z-0 lg:h-[105vh] w-auto lg:left-52 top-[80vh] lg:-top-10"
          alt="gradiant"
          src="/group-1@2x.png"
        />
      </div>
    </section>
  );
}

export default PrimaryFeatures;
